<template>
    <settingsPagesLayout>
        <div class="p-inside-manage">
            <div class="page_title">Security</div>
            <section class="wrap_s_block change_password">
                <div class="password_box">
                    <div class="title_section">
                        <h2 class="name_section">Password</h2>
                    </div>

                    <form action="#" @submit.prevent="goChangePassword" autocomplete="off">
                        <div class="body_ change_password">
                            <div class="wrap_input password">
                                <password-field
                                        id="old-password"
                                        autocomplete="old-password'"
                                        v-model="oldPassword"
                                        label="Current Password"
                                        :error-messages="oldPasswordErrorMessages"
                                ></password-field>
                            </div>

                            <div class="wrap_input password">
                                <password-field
                                        id="new-password"
                                        autocomplete="new-password'"
                                        v-model="newPassword"
                                        label="New Password"
                                ></password-field>
                                <div class="error-msg" v-if="!$v.newPassword.$error && !$v.newPassword.maxLength">Password must be 128 characters or less</div>
                            </div>

                            <div class="password_complexity">
                                <span class="label">Password must include:</span>
                                <div class="wrap_must_symbols">
                                    <div :class="[ {active: this.passwordComplexity.upperCase}, 'item_' ]">A-Z</div>
                                    <div :class="[ {active: this.passwordComplexity.lowerCase}, 'item_']">a-z</div>
                                    <div :class="[ {active: this.passwordComplexity.numbers}, 'item_']">123</div>
                                    <div :class="[ {active: this.passwordComplexity.nonWordChars}, 'item_']">!@#</div>
                                    <div :class="[ {active: newPassword.length >= 8}, 'item_']">8+ Chars</div>
                                </div>
                            </div>
                            <v-btn
                                    class="btn_change_pass"
                                    :class="{'loading': passwordFormLoader}"
                                    :disabled="!isValidChangePasswordForm || (!$v.newPassword.$error && !$v.newPassword.maxLength) || passwordFormLoader"
                                    @click.stop="goChangePassword">
                                <template v-if="!passwordFormLoader">Save Changes</template>
                                <template v-else>
                                    <v-progress-circular
                                        :width="2"
                                        :size="20"
                                        indeterminate
                                        color="white"
                                    ></v-progress-circular>
                                </template>
                            </v-btn>
                        </div>
                    </form>
                </div>
            </section>
            <section class="wrap_s_block change_email">
                <div class="email_box">
                    <div class="title_section">
                        <h2 class="name_section">Email</h2>
                    </div>
                    <div class="body_ change_email">
                        <div class="this_email" v-if="PROFILE && PROFILE.email">
                            {{ PROFILE.email }}
                            <span class="unconfirmed" v-if="PROFILE.status !== 'active'">(Unconfirmed)</span>
                        </div>
                    </div>
                </div>
            </section>
            <dialogChangeEmail v-if="dialogChangeEmailVisible" v-model="dialogChangeEmailVisible"></dialogChangeEmail>
        </div>
    </settingsPagesLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import settingsPagesLayout from "@/layouts/settingsPagesLayout";
import PasswordField from "@/components/profile/passwordField";
import {maxLength, required} from "vuelidate/lib/validators";

const dialogChangeEmail = () => import('@/components/dialogs/changeEmail');

export default {
    name: 'security',
    components: {
        settingsPagesLayout,
        PasswordField,
	    dialogChangeEmail
    },
    data() {
        return {
            passwordFormLoader: false,
            oldPasswordErrorMessages: [],
            oldPassword: '',
            newPassword: '',
            passwordComplexity: {
                lowerCase: false,
                upperCase: false,
                nonWordChars: false,
                numbers: false,
                minLength: false
            },
            dialogChangeEmailVisible: false,
            timer: 0
        }
    },
	validations: {
		oldPassword: {
			required,
			maxLength: maxLength(128)
		},
		newPassword: {
			required,
			maxLength: maxLength(128)
		}
	},
    watch: {
        newPassword() {
            this.passwordComplexity.nonWordChars = !!this.newPassword.match(/[!@#$%^&*().,;:{}_+=<>?|~-]/);
            this.passwordComplexity.upperCase = !!this.newPassword.match(".*[A-Z]");
            this.passwordComplexity.lowerCase = !!this.newPassword.match(".*[a-z]");
            this.passwordComplexity.numbers = !!this.newPassword.match(/[0-9]/);
            this.passwordComplexity.minLength = this.newPassword.length >= 8;
        }
    },
    computed: {
        ...mapGetters(['PROFILE', 'CHANGE_PASSWORD_STATUS']),
        isValidChangePasswordForm() {
            return this.passwordComplexity.lowerCase === true &&
                this.passwordComplexity.upperCase === true &&
                this.passwordComplexity.nonWordChars === true &&
                this.passwordComplexity.numbers === true &&
                this.passwordComplexity.minLength === true &&
                this.oldPassword.length > 0;
        }
    },
    created() {
        this.GET_PROFILE()
            .catch(err => {console.error('security, GET_PROFILE', err)});
    },
    methods: {
        ...mapActions(['GET_PROFILE', 'SEND_EMAIL_VERIFICATION']),

        goChangePassword() {
            this.passwordFormLoader = true;

            const data = {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword
            }

            if (this.oldPassword && this.newPassword) {
                this.$store.dispatch('CHANGE_PASSWORD', data)
                    .then(() => {
                        this.passwordFormLoader = false;

                        if (this.CHANGE_PASSWORD_STATUS === 'success') {
                            this.$store.dispatch('LOGOUT').then(() => {
                                this.$router.push('/password-changed');
                            });
                        } else {
                            this.oldPasswordErrorMessages = ['The current password that you entered is incorrect'];
                        }
                    });
            }
        },
        changeEmail() {
            this.dialogChangeEmailVisible = true;
        },
        resendEmailConfirmation() {
            this.timer = 60;
            this.SEND_EMAIL_VERIFICATION().then(() => {
                this.countDownTimer();
            })
        },
        countDownTimer() {
            if (this.timer > 0) {
                setTimeout(() => {
                    this.timer -= 1;
                    this.countDownTimer();
                }, 1000);
            }
        },
    }
}
</script>

<style scoped>
/* Reset the container widths */
.wrap_s_block {
    width: 100%;
    box-sizing: border-box;
}

.email_box {
    width: 100%;
    box-sizing: border-box;
}

.change_email {
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.this_email {
    width: 100%;
    box-sizing: border-box;
    word-break: break-all;
    overflow-wrap: anywhere;
    display: inline-block;
    padding: 15px;
    margin: 0;
}

.unconfirmed {
    margin-left: 4px;
    color: #666;
    white-space: nowrap;
}

@media screen and (max-width: 768px) {
    .change_email {
        padding: 0;
    }
    
    .this_email {
        font-size: 14px;
        padding: 15px;
    }
}
</style>
